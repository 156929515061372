/**
 * @file 首页 运营位
 * @author FengGuang(fengguang01@baidu.com)
 */
import React, {useMemo} from 'react';
import {Row} from 'antd';

import {IOperatorGird, IOperatorGirdCarouselItem, IOperatorGirdItem} from '../../types';
import OperatorGirdItem from './OperatorGirdItem';
import PaddleCarousel from '../paddle-carousel/PaddleCarousel';
import OperatorCarouselItem from './OperatorCarouselItem';


const OperatorGird: React.FC<IOperatorGird> = React.memo(props => {
    const carousel = useMemo(() => {
        return Array.isArray(props.carousel)
            ? props.carousel.map((v, i) => ({...v, key: v.title || ('' + i)}))
            : Array.from({length: 1}).map<IOperatorGirdCarouselItem>((_, i) => ({
                key: '' + i
            }));
    }, [props.carousel]);

    const items = useMemo(() => {
        return Array.isArray(props.items)
            ? props.items.map((v, i) => ({...v, key: v.title || ('' + i)}))
            : Array.from({length: 6}).map<IOperatorGirdItem>((_, i) => ({
                key: '' + i
            }));
    }, [props.items]);

    return (
        <div className="paddle-operator-gird clear-both">
            {/* 下掉了 */}
            {/* <div className="paddle-operator-gird-carousel-wrap">
                <PaddleCarousel
                    className="paddle-operator-gird-carousel"
                    autoplay
                    autoplaySpeed={4000}
                >
                    {carousel.map(item => (
                        <OperatorCarouselItem key={item.key} {...item} />
                    ))}
                </PaddleCarousel>
            </div> */}
            <div className="paddle-operator-gird-item-list">
                <Row gutter={[10, 10]}>
                    {items.map(item => (
                        <OperatorGirdItem
                            key={item.key}
                            {...item}
                        />
                    ))}
                </Row>
            </div>
        </div>
    );
});

export default OperatorGird;
