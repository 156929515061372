/**
 * @file 增设3.0正式版核心特性介绍板块
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { useMemo } from 'react';
import classNames from 'classnames';

import {IIntroduceList} from '../../types';

const IntroduceList: React.FC<IIntroduceList> = React.memo((props) => {
    const list = props?.list ?? [];
    return (
        <div className='paddle-home-introduce'>
            {
                list.map((item) => (
                    <a 
                        className='paddle-home-introduce-card' 
                        href={item.link} 
                        target={item.target ? '_blank' : undefined}
                        rel={item.target ? 'noopener noreferrer' : undefined}
                    >
                        <div className='paddle-home-introduce-card-title'>
                            {item.title}
                        </div>
                        <div className='paddle-home-introduce-card-desc'>
                            {item.text}
                        </div>
                    </a>
                ))
            }
        </div>
    );
});

export default IntroduceList;
