/**
 * @file paddle 首页 视频列表
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback, useRef} from 'react';
import {Col} from 'antd';
import Icon from '@ant-design/icons';

import VideoCover from '../../../../components/video-cover/VideoCover';
import {IVideoListListItem} from '../../types';
import VideoModal from '../../../../components/video-modal/VideoModal';

import {ReactComponent as PlayIcon} from './image/play.svg';

const VideoListItem: React.FC<IVideoListListItem> = props => {
    const videoCoverRef = useRef<HTMLVideoElement>(null);
    const onMouseEnter = useCallback(() => {
        if (videoCoverRef.current) {
            videoCoverRef.current.play();
        }
    }, []);
    const onMouseLeave = useCallback(() => {
        if (videoCoverRef.current) {
            videoCoverRef.current.pause();
        }
    }, []);

    const renderContext = (
        <Col xs={8}>
            <div
                className="paddle-index-video-list-item"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div
                    className="paddle-index-video-list-item-background"
                    style={{
                        backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : undefined
                    }}
                >
                    {!!props.backgroundVideo
                    && (
                        <VideoCover
                            ref={videoCoverRef}
                            src={props.backgroundVideo}
                            loop
                        />
                    )}
                    <div className="paddle-index-video-list-item-background-mask" />
                </div>
                <div className="paddle-index-video-list-item-content">
                    <div
                        className="paddle-index-video-list-item-tag"
                        style={{color: props.tagTextColor}}
                    >
                        {props.tagText} <Icon component={PlayIcon} />
                    </div>
                    <div
                        className="paddle-index-video-list-item-title"
                        style={{color: props.titleColor}}
                    >
                        {props.title}
                    </div>
                </div>
            </div>
        </Col>
    );

    return (
        <VideoModal src={props.link}>
            {renderContext}
        </VideoModal>
    );
};

export default VideoListItem;
