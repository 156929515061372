/**
 * @file 产品全景
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React from 'react';
import {Col, Row} from 'antd';
import {IPartner} from '../../types';
import A from '../../../../components/a/A';
import VerticalEllipsis from '../../../../components/vertical-ellipsis/VerticalEllipsis';

const Partner: React.FC<IPartner> = React.memo(props => {
    return (
        <div className="paddle-index-partner">
            <div className="paddle-index-partner-left">
                {props.textCard.map(item => (
                    <div
                        key={`${item.title}${item.text}`}
                        className="paddle-index-partner-card"
                    >
                        <div className="paddle-index-partner-card-title">
                            {item.title}
                        </div>
                        <div className="paddle-index-partner-card-text">
                            <VerticalEllipsis title={item.text}>
                                {item.text}
                            </VerticalEllipsis>
                        </div>
                    </div>
                ))}
            </div>
            <div className="paddle-index-partner-list">
                <Row>
                    {props.list.map(partner => (
                        <Col
                            xs={6}
                            key={`${partner.title}${partner.imgUrl}${partner.link}`}
                            className="paddle-index-partner-item-wrap"
                        >
                            <A
                                href={partner.link}
                                target={partner.target ? '_blank' : undefined}
                                rel={partner.target ? 'noopener noreferrer' : undefined}
                            >
                                <div className="paddle-index-partner-item">
                                    <div
                                        className="paddle-index-partner-item-background"
                                        style={{backgroundImage: `url(${partner.imgUrl})`}}
                                    />
                                </div>
                            </A>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
});

export default Partner;
