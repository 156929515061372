/**
 * @file 产品全景产品
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React from 'react';
import {Col} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import {IProductListItem} from '../../types';
import A from '../../../../components/a/A';

const ProductListItem: React.FC<IProductListItem> = props => {
    return (
        <Col xs={props?.col ?? 8} className="paddle-index-product-list-item-wrap">
            <A
                href={props.link}
                target={props.target ? '_blank' : undefined}
                rel={props.target ? 'noopener noreferrer' : undefined}
            >
                <div className="paddle-index-product-list-item">
                    <div className="paddle-index-product-list-item-title">
                        {props.title}
                        {!!props.tags?.length && props.tags?.map(tag => (
                            <span
                                key={`${tag.text}${tag.type}${tag.color}`}
                                className={classNames(
                                    'paddle-index-product-list-item-tag',
                                    tag.type ? `paddle-index-product-list-item-tag-${tag.type}` : undefined
                                )}
                                style={{color: tag.color, borderColor: tag.color}}
                            >
                                {tag.text}
                            </span>
                        ))}
                        {!!props.link && <ArrowRightOutlined />}
                    </div>
                    <div className="paddle-index-product-list-item-text">
                        {props.text}
                    </div>
                </div>
            </A>
        </Col>
    );
};

export default ProductListItem;
