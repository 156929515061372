/**
 * @file 首页banner
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, { useMemo } from 'react';
import classNames from 'classnames';

import {IHomeBanner} from '../../types';

import bannerBg from '../../../../images/homeBanner/home_banner.png'

const HomeBanner: React.FC<IHomeBanner> = React.memo((props) => {
    return (
        (props?.title || props?.subtitle) && <div className='paddle-home-banner-wrap' style={{ backgroundImage: `url(${bannerBg})` }}>
            <div className="paddle-home-banner">
                <div className="paddle-home-banner-title">{props?.title ?? ''}</div>
                <div
                    className="paddle-home-banner-subtitle"
                >
                    {props?.subtitle ?? ''}
                </div>
                {
                    !!props?.btns?.length && (
                        <a 
                            target={props?.btns?.[0].target ? '_blank' : undefined}
                            rel={props?.btns?.[0].target ? 'noopener noreferrer' : undefined}
                            href={props?.btns?.[0].link} 
                            className="paddle-home-banner-btn">{props?.btns?.[0].title ?? ''}</a>
                    )
                }
            </div>
        </div>
    );
});

export default HomeBanner;
