/**
 * @file paddle 首页
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {
    RightOutlined
} from '@ant-design/icons';

import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/footer';
import HomeBanner from './components/homeBanner/HomeBanner';
import IntroduceList from './components/introduceList/IntroduceList';
import useIndexStore from './indexHooks';
import QuickStart from './components/quick-start/QuickStart';
import OperatorGird from './components/operator-gird/OperatorGird';
import VideoList from './components/video-list/VideoList';
import IconTextList from './components/icon-text-list/IconTextList';
import PictureTextCardGroup from './components/picture-text-card/PictureTextCardGroup';
import IconTextCard from './components/icon-text-card/IconTextCard';
import A from '../../components/a/A';
import ProductList from './components/product-list/ProductList';
import Partner from './components/partner/Partner';
import News from './components/news/News';
// import PaddleTimeline from './components/timeline/PaddleTimeline';
import RightNavigation from '../../components/right-navigation/RightNavigation';


const IndexIndex: React.FC = () => {
    const {
        operatorGird,
        iconTextList,
        quickInstall,
        pictureTextCard,
        productList,
        news,
        iconTextCard,
        videoList,
        partner,
        introduceList,
        homeBanner,
        // timeline,

        quickStartHooks
    } = useIndexStore();

    return (
        <div className="page paddle-index-page">
            <Header />
            {!!homeBanner && <HomeBanner {...homeBanner}/>}
            <div className="paddle-index-content">
                {!!introduceList && <IntroduceList {...introduceList} />}

                <div className="clear-both">
                    {!!iconTextList
                    && (
                        <div className="paddle-index-right">

                            <div className="paddle-index-context-min-h2">
                                {iconTextList.title}
                            </div>
                            {!!iconTextList.subtitle
                            && (
                                <div
                                    className="paddle-index-context-min-h4"
                                    dangerouslySetInnerHTML={{__html: iconTextList.subtitle || ''}}
                                />
                            )}
                            <IconTextList {...iconTextList} />
                        </div>
                    )}

                    {!!quickInstall
                    && (
                        <div className="paddle-index-left">

                            <div className="paddle-index-context-min-h2">
                                {quickInstall.title}
                            </div>
                            {!!quickInstall.subtitle
                            && (
                                <div
                                    className="paddle-index-context-min-h4"
                                    dangerouslySetInnerHTML={{__html: quickInstall.subtitle || ''}}
                                />
                            )}

                            <QuickStart
                                trackeventOptLabel="首页"
                                {...quickStartHooks}
                            />
                        </div>
                    )}
                </div>

                {!!productList
                    && (
                        <div className="paddle-index-product-list-wrap">
                            <div className="paddle-index-content">
                                <div className="paddle-index-context-wrap">
                                    <div className="paddle-context-h2">
                                        {productList.title}
                                    </div>
                                    {!!productList.subtitle
                                    && (
                                        <div
                                            className="paddle-context-h3"
                                            /* bca-disable */
                                            dangerouslySetInnerHTML={{__html: productList.subtitle || ''}}
                                        />
                                    )}
                                    {!!productList.checkAll && productList.checkAll.text
                                    && (
                                        <div className="paddle-index-context-link-more">
                                            <A
                                                href={productList.checkAll.link}
                                                target={productList.checkAll.target ? '_blank' : undefined}
                                            >
                                                {productList.checkAll.text} <RightOutlined />
                                            </A>
                                        </div>
                                    )}
                                </div>
                                <ProductList {...productList} />
                            </div>
                        </div>
                    )}


                {!!pictureTextCard
                && (
                    <React.Fragment>
                        <div className="paddle-index-context-wrap">
                            <div className="paddle-context-h2">
                                {pictureTextCard.title}
                            </div>
                            {!!pictureTextCard.subtitle
                            && (
                                <div
                                    className="paddle-context-h3"
                                    dangerouslySetInnerHTML={{__html: pictureTextCard.subtitle || ''}}
                                />
                            )}
                            {!!pictureTextCard.checkAll && pictureTextCard.checkAll.text
                            && (
                                <div className="paddle-index-context-link-more">
                                    <A
                                        href={pictureTextCard.checkAll.link}
                                        target={pictureTextCard.checkAll.target ? '_blank' : undefined}
                                    >
                                        {pictureTextCard.checkAll.text} <RightOutlined />
                                    </A>
                                </div>
                            )}
                        </div>
                        <PictureTextCardGroup {...pictureTextCard} />
                    </React.Fragment>
                )}

                {!!videoList
                && (
                    <>
                        <div className="paddle-context-h2">
                            {videoList.title}
                        </div>
                        {!!videoList.subtitle
                        && (
                            <div
                                className="paddle-context-h3"
                                /* bca-disable */
                                dangerouslySetInnerHTML={{__html: videoList.subtitle || ''}}
                            />
                        )}
                        <VideoList {...videoList} />
                    </>
                )}

            </div>

            <div className="paddle-index-content">
                {!!news
                && (
                    <React.Fragment>
                        <div className="paddle-index-context-wrap">
                            <div className="paddle-context-h2">
                                {news.title}
                            </div>
                            {!!news.subtitle
                            && (
                                <div
                                    className="paddle-context-h3"
                                    dangerouslySetInnerHTML={{__html: news.subtitle || ''}}
                                />
                            )}
                            {!!news.checkAll && news.checkAll.text
                            && (
                                <div className="paddle-index-context-link-more">
                                    <A
                                        href={news.checkAll.link}
                                        target={news.checkAll.target ? '_blank' : undefined}
                                    >
                                        {news.checkAll.text} <RightOutlined />
                                    </A>
                                </div>
                            )}
                        </div>
                        <News {...news} />
                    </React.Fragment>
                )}

                <OperatorGird {...operatorGird} />

                {!!iconTextCard
                && (
                    <>
                        <div className="paddle-index-context-wrap">
                            <div className="paddle-context-h2">
                                {iconTextCard.title}
                            </div>
                            {!!iconTextCard.subtitle
                            && (
                                <div
                                    className="paddle-context-h3"
                                    dangerouslySetInnerHTML={{__html: iconTextCard.subtitle || ''}}
                                />
                            )}
                        </div>
                        <IconTextCard {...iconTextCard} />
                    </>
                )}

                {!!partner
                && (
                    <>
                        <div className="paddle-index-context-wrap">
                            <div className="paddle-context-h2">
                                {partner.title}
                            </div>
                            {!!partner.subtitle
                            && (
                                <div
                                    className="paddle-context-h3"
                                    dangerouslySetInnerHTML={{__html: partner.subtitle || ''}}
                                />
                            )}
                            {!!partner.checkAll && partner.checkAll.text
                            && (
                                <div className="paddle-index-context-link-more">
                                    <A
                                        href={partner.checkAll.link}
                                        target={partner.checkAll.target ? '_blank' : undefined}
                                    >
                                        {partner.checkAll.text} <RightOutlined />
                                    </A>
                                </div>
                            )}
                        </div>
                        <Partner {...partner} />
                    </>
                )}

            </div>

            <RightNavigation />
            <Footer />
        </div>
    );
};

export default IndexIndex;
